<template>
<div >
  <LinesBackground class="linesBackground" />
  <div class="logo">
    <logo />
  </div>
  <div class="switcher">
    <NewThemeSwitch />
  </div>
  <div class="lang-change-trigger">
    <LanguageSwitcher />
  </div>

  <div class="login__wrapper" @keyup.enter="onConfirmVerification">
    <div class="twoFactor">
      <h1 class="twoFactor__title">{{ $t('authorization.verif-title') }}</h1>
      <h2 class="twoFactor__subtitle">{{ $t('authorization.verification-description') }}</h2>
      <div class="login-input__wrapper">
        <label class="login-input__label" for="Code">{{ $t('authorization.enter-code') }}</label>
        <input class="login-input" name="Code" v-model="twoFactorCode" :placeholder="$t('authorization.code')" maxlength="15" />
      </div>
      <div v-if="errorCode" class="error-banner">
        <svg class="error-banner__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.80977 0.21967C6.95042 0.0790176 7.14118 0 7.3401 0H16.6599C16.8588 0 17.0496 0.0790176 17.1902 0.21967L23.7803 6.80977C23.921 6.95042 24 7.14118 24 7.3401V16.6599C24 16.8588 23.921 17.0496 23.7803 17.1902L17.1902 23.7803C17.0496 23.921 16.8588 24 16.6599 24H7.3401C7.14118 24 6.95042 23.921 6.80977 23.7803L0.21967 17.1902C0.0790176 17.0496 0 16.8588 0 16.6599V7.3401C0 7.14118 0.0790176 6.95042 0.21967 6.80977L6.80977 0.21967ZM7.65076 1.5L1.5 7.65076V16.3492L7.65076 22.5H16.3492L22.5 16.3492V7.65076L16.3492 1.5H7.65076Z" fill="#BE4040"/>
          <path d="M10.5023 16.5C10.5023 15.6716 11.1739 15 12.0023 15C12.8307 15 13.5023 15.6716 13.5023 16.5C13.5023 17.3284 12.8307 18 12.0023 18C11.1739 18 10.5023 17.3284 10.5023 16.5Z" fill="#BE4040"/>
          <path d="M10.6493 7.49256C10.5693 6.69343 11.1969 6 12 6C12.8031 6 13.4307 6.69343 13.3507 7.49256L12.8246 12.7537C12.7823 13.1774 12.4258 13.5 12 13.5C11.5742 13.5 11.2177 13.1774 11.1754 12.7537L10.6493 7.49256Z" fill="#BE4040"/>
        </svg>
        <span class="error-banner__text">{{ errorMsg }}</span>
      </div>
      <NButton @click="onConfirmVerification"  class="twoFactor__btn">{{ $t('authorization.send') }}</NButton>
      <p class="twoFactor__logout"><a href="/logout" class="login-description__link">{{ $t('authorization.logout') }}</a></p>
    </div>
  </div>
</div>

</template>

<script>
import axios from "axios";
import {v4 as UUID} from "uuid";
import ChangeLanguageModal from "@/public/components/modals/ChangeLanguageModal";
import NewThemeSwitch from "@/public/components/elements/NewThemeSwitch";
import LinesBackground from "@/public/components/elements/LinesBackground";
import NButton from "@/public/components/common/NButton";
import {mapState} from "vuex";
import LanguageSwitcher from "@/public/components/common/LanguageSwitcher";
import {lkLogoLoader} from "@/public/utils/logo-loader";

export default {
    name: "login-confirm",
  components: {LanguageSwitcher, NButton, LinesBackground, Logo: lkLogoLoader(), NewThemeSwitch, ChangeLanguageModal},
  data() {
        return {
            code: '',
            pending: false,
            twoFactorCode: '',
            errorCode: '',
            errorMsg: ''
        };
    },
    methods: {
        async onConfirmVerification() {
          if(this.pending) {
            return;
          }

            this.pending = true;

            if (this.twoFactorCode.length < 6) {
                this.errorCode = -1
                this.errorMsg = this.$t('two-fa-confirmation.error_length')
                this.pending = false;
                return
            }

            try {
              window.$$beforeLoginEventTrigger = false;

                const {data} = await axios.post('/public-api/login/2fa', {
                    code: this.twoFactorCode,
                  rid: UUID(),
                });


                if(!data.success) {
                    /*
                    TWO_FA_CODE_REQUIRED: 3002,
                    TWO_FA_CODE_INCORRECT: 3003,
                    * */
                  this.errorCode = data.code
                    switch (data.code) {
                        case 3002:
                            this.errorMsg = this.$t('authorization.code-not-entered')
                            break;
                        case 3003:
                          this.errorMsg = this.$t('authorization.code-not-valid')
                            break;
                        default:
                            break;
                    }

                  this.pending = false;
                } else {
                    // this.$store.commit('app/setAuthTwoFa', true);
                    // await this.$store.dispatch('app/init', null);

                    // this.$router.push({name: 'dashboard'});

                  if(window.$$beforeLoginEventTrigger) {
                  } else {
                    const timer = setTimeout(() => {
                      window.location.href = window.location.href+'';
                    }, 5000);

                    window.$$beforeLoginEventTrigger = () => {
                      clearTimeout(timer);

                      this.pending = false;
                    };
                  }
                }

            } catch (e) {
                this.pending = false;

                await new Promise(r => setTimeout(r, 1000));

                return this.onSubmit();
            }
        }
    },
}
</script>

<style lang="scss" scoped>
* {
  transition: all .2s ease;
}
.login {
  &__wrapper {
    font-family: 'Gilroy';
    background: var(--new-front-gradient-bg);
    height: 100vh;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-form {
    &__title {
      font-size: 36px;
      margin-bottom: 50px;
      font-weight: 500;
      color: var(--new-front-primary-font-color);
    }
    &__wrapper {
      z-index: 2;
      padding: 30px;
      background: var(--new-front-main-bg);
      border-radius: 30px;
      max-width: 640px;
      width: 100%;
    }
  }
  &-input {
    &__label {
      color: var(--new-front-primary-label-color);
      font-size: 18px;
      margin-bottom: 15px;
    }
    &__wrapper {
      margin-bottom: 20px;
    }
  }
  &-button {
    font-weight: 500;
    font-size: 18px;
    background: #744DB2;
    border-radius: 10px;
    line-height: 28px;
    color: #ffffff;
    padding: 10px;
    width: 100%;
    outline: none;
    border: none;
    margin-top: 30px;
    cursor: pointer;
  }
  &-description {
    margin-top: 30px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: var(--new-front-primary-font-color);
    &__link {
      color:#6750A4 !important;
    }
  }
}
.login-input {
  width: 100%;
  border-radius: 9px;
  outline: none;
  padding: 15px;
  border: 1px var(--new-front-input-border) solid;
  font-size: 18px;
  margin-top: 10px;
  background: var(--new-front-input-bg);
  color: var(--new-front-primary-font-color);
}
.logo {
  position: absolute;
  top: 30px;
  left: 30px;
}
.linesBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.switcher {
  position: absolute;
  top: 30px;
  right: 30px;
}
.error-banner {
  margin-left: 12px;
  display: flex;
  align-items: center;
  &__icon {
    margin-right: 20px;
  }
  &__text {
    color: #BE4040;
    font-weight: 500;
    font-size: 16px;
  }
}
.twoFactor {
  z-index: 2;
  padding: 30px;
  background: var(--new-front-main-bg);
  border-radius: 30px;
  max-width: 640px;
  width: 100%;
  &__title {
    font-weight: 500;
    font-size: 36px;
    color: var(--new-front-primary-font-color);
    text-align: center;
    margin-bottom: 30px;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 18px;
    color: var(--new-front-primary-label-color);
    text-align: center;
    margin-bottom: 50px;
  }
  &__btn {
    margin: 50px 0;
  }
  &__logout {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
  }
}
.lang-change-trigger {
  position: absolute;
  right: 132px;
  top: 38px;
}
@media (max-width: 556px) {
  .login {
    &-description {
      font-size: 13px;
    }
    &__wrapper {
      padding: 10px;
    }
    &-form {
      &__wrapper {
        padding: 20px;
        border: 1px solid var(--new-front-border-color);
      }
      &__title {
        font-size: 24px;
        margin-bottom: 30px;
      }
    }
  }
  .login-input {
    font-size: 13px;
    &__label {
      font-size: 13px;
      margin-top: 10px;
    }
  }
  .error-banner {
    margin-left: 12px;
    display: flex;
    align-items: center;
    &__icon {
      margin-right: 15px;
    }
    &__text {
      color: #BE4040;
      font-weight: 500;
      font-size: 13px;
    }
  }

  .logo {
    left: 10px;
    top: 10px;
  }
  .switcher {
    right: 10px;
    top: 10px;
  }
  .lang-change-trigger {
    position: absolute;
    right: 105px;
    top: 17px;
  }
}
</style>
